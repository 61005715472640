exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreement-signed-js": () => import("./../../../src/pages/agreement-signed.js" /* webpackChunkName: "component---src-pages-agreement-signed-js" */),
  "component---src-pages-airlines-tsx": () => import("./../../../src/pages/airlines.tsx" /* webpackChunkName: "component---src-pages-airlines-tsx" */),
  "component---src-pages-bank-details-tsx": () => import("./../../../src/pages/bank-details.tsx" /* webpackChunkName: "component---src-pages-bank-details-tsx" */),
  "component---src-pages-claim-form-booking-details-tsx": () => import("./../../../src/pages/claim-form/booking-details.tsx" /* webpackChunkName: "component---src-pages-claim-form-booking-details-tsx" */),
  "component---src-pages-claim-form-claim-registered-tsx": () => import("./../../../src/pages/claim-form/claim-registered.tsx" /* webpackChunkName: "component---src-pages-claim-form-claim-registered-tsx" */),
  "component---src-pages-claim-form-compensation-estimate-tsx": () => import("./../../../src/pages/claim-form/compensation-estimate.tsx" /* webpackChunkName: "component---src-pages-claim-form-compensation-estimate-tsx" */),
  "component---src-pages-claim-form-flight-details-tsx": () => import("./../../../src/pages/claim-form/flight-details.tsx" /* webpackChunkName: "component---src-pages-claim-form-flight-details-tsx" */),
  "component---src-pages-claim-form-index-tsx": () => import("./../../../src/pages/claim-form/index.tsx" /* webpackChunkName: "component---src-pages-claim-form-index-tsx" */),
  "component---src-pages-claim-form-passengers-tsx": () => import("./../../../src/pages/claim-form/passengers.tsx" /* webpackChunkName: "component---src-pages-claim-form-passengers-tsx" */),
  "component---src-pages-delayed-flights-tsx": () => import("./../../../src/pages/delayed-flights.tsx" /* webpackChunkName: "component---src-pages-delayed-flights-tsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-airline-page-airline-page-tsx": () => import("./../../../src/templates/AirlinePage/AirlinePage.tsx" /* webpackChunkName: "component---src-templates-airline-page-airline-page-tsx" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-news-page-news-page-js": () => import("./../../../src/templates/NewsPage/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-news-page-js" */)
}

