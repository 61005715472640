/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'whatwg-fetch'

import './src/global.scss'
import {activateLocale} from './src/locales'

// Dummy export to make the whatwg-fetch import work
export const onClientEntry = () => {
    activateLocale(document.location)
}
