import _ from 'lodash'
import { addLocale, useLocale} from 'ttag'

import { default as PO_DATA_EN } from './po/en.po.json'
import { default as PO_DATA_ES } from './po/es.po.json'
import { default as PO_DATA_IT } from './po/it.po.json'
import { default as PO_DATA_LV } from './po/lv.po.json'
import { default as PO_DATA_RU } from './po/ru.po.json'


type Location = {
    pathname: string
}

type Locale = {
    code: string
    shortCode: string
    flagCode: string
    label: string
    poData?: any
    ttagLocaleAdded?: boolean
    hide?: boolean
}

const DEFAULT_CODE = 'en-US'

const LOCALES: Locale[] = [
    {
        code: DEFAULT_CODE,
        shortCode: 'en',
        flagCode: 'gb',
        label: 'English',
        poData: PO_DATA_EN,
    },
    {
        code: 'es',
        shortCode: 'es',
        flagCode: 'es',
        label: 'Español',
        poData: PO_DATA_ES,
    },
    {
        code: 'it',
        shortCode: 'it',
        flagCode: 'it',
        label: 'Italiano',  // TODO: Double check, not taken from an authoritative source
        poData: PO_DATA_IT,
    },
    {
        code: 'lv',
        shortCode: 'lv',
        flagCode: 'lv',
        label: 'Latviski',
        poData: PO_DATA_LV,
    },
    {
        code: 'ru',
        shortCode: 'ru',
        flagCode: 'ru',
        label: 'Pусский',
        poData: PO_DATA_RU,
    },
]

const getDefaultLocale = () => {
    return _.find(LOCALES, { code: DEFAULT_CODE })
}

const getLocale = (location: Location) : Locale => {
    const [firstFragment] = location.pathname.slice(1).split('/')

    if (!firstFragment) {
        return getDefaultLocale()
    }

    const locale = _.find(LOCALES, { shortCode: firstFragment })

    if (!locale) {
        return getDefaultLocale()
    }

    return locale
}

export function getLanguageCode (location: Location) : string {
    return getLocale(location).shortCode
}

export function activateLocale (location: Location) {
    let locale = getLocale(location)
    if (!locale.ttagLocaleAdded) {
        addLocale(locale.shortCode, locale.poData)
        locale.ttagLocaleAdded = true
    }
    useLocale(locale.shortCode)
}

export { Location, Locale, DEFAULT_CODE, LOCALES, getLocale }
